<template>
  <div class="accordion">
    <div class="accordion_header">
      <div class="media">
        <div class="media-content" v-if="actualSale.sale">
          <div class="columns is-multiline is-mobile">
            <div class="column is-3-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold">Nº. DE PEDIDO</p>
              <p class="text_16">{{ actualSale.sale.order_number | replaceIfLogicalFalse('') }}</p>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold">PEDIDO REALIZADO</p>
              <p class="text_16">{{ moment(actualSale.sale.created_at).format('DD/MMM/YYYY') | replaceIfLogicalFalse('') }}</p>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold">TOTAL</p>
              <p class="text_16">{{ numeral(actualSale.sale.total).format('$0,0.00') | replaceIfLogicalFalse('') }}</p>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold">VENDIDO A</p>
              <p class="text_16">{{ actualSale.sale.name | replaceIfLogicalFalse('') }} {{ actualSale.sale.last_name | replaceIfLogicalFalse('') }}</p>
            </div>
          </div>
        </div>
        <div class="media-right">
          <span class="material-icons keyboard_arrow_icon" @click="accordionActive = !accordionActive">
            {{ accordionActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </span>
        </div>
      </div>
    </div>
    <div class="accordion_block" v-if="accordionActive">
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="columns is-multiline is-mobile">
            <!-- Información de contacto -->
            <div class="column is-12 pb-0 has-text-centered">
              <p class="text_18 title_media has-text-weight-bold text_primary_green"> INFORMACIÓN DE CONTACTO </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Empresa: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.sale.company | replaceIfLogicalFalse('') }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Teléfono: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.sale.phone | replaceIfLogicalFalse('') }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Email: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.sale.email | replaceIfLogicalFalse('') }} </p>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="columns is-multiline is-mobile">
            <!-- Método de pago -->
            <div class="column is-12 pb-0 has-text-centered">
              <p class="text_18 title_media has-text-weight-bold text_primary_green"> MÉTODO DE PAGO </p>
            </div>
            <div class="column is-12 pb-0 has-text-centered">
              <p class="text_18">{{ actualSale.sale.payment_method == 'otherPaymentMethod' ? 'Transferencia Bancaria' : 'Tarjeta de crédito/débito' | replaceIfLogicalFalse('') }}</p>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="columns is-multiline is-mobile">
            <!-- Resumen del pedido -->
            <div class="column is-12 pb-0 has-text-centered">
              <p class="title_media text_18 has-text-weight-bold text_primary_green"> RESUMEN DEL PEDIDO </p>
            </div>
            <div class="column py-0 is-6-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right"> Subtotal: </p>
            </div>
            <div class="column py-0 is-6-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> ${{ numeral(actualSale.sale.subtotal).format('0,0.00') | replaceIfLogicalFalse('') }} MXN </p>
            </div>
            <div class="column py-0 is-6-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right"> IVA: </p>
            </div>
            <div class="column py-0 is-6-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> ${{ numeral(actualSale.sale.taxes).format('0,0.00') | replaceIfLogicalFalse('') }} MXN </p>
            </div>
            <div class="column py-0 is-6-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right"> Total: </p>
            </div>
            <div class="column py-0 is-6-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-weight-bold"> ${{ numeral(actualSale.sale.total).format('0,0.00') | replaceIfLogicalFalse('') }} MXN</p>
            </div>
          </div>
        </div>
        <div class="column is-6" v-if="actualSale.sale.get_tax_bill">
          <div class="columns is-multiline is-mobile">
            <!-- Datos de facturación -->
            <div class="column is-12 pb-0 pt-6 has-text-centered">
              <p class="text_18 title_media has-text-weight-bold text_primary_green"> DATOS DE FACTURACIÓN </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Tipo de persona: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.personType }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Empresa: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.company }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Nombre: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.name }} {{ actualSale.billingForm.lastname }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Dirección: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.address }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> RFC: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.rfc }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Uso CFDI: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.cfdi }} </p>
            </div>
            <div class="column py-0 is-5-desktop is-6-tablet is-6-mobile">
              <p class="text_18 has-text-right has-text-weight-bold"> Email para recibir factura: </p>
            </div>
            <div class="column py-0 is-7-desktop is-6-tablet is-6-mobile">
              <p class="text_18"> {{ actualSale.billingForm.email }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion_footer is_grid" v-if="accordionActive && actualSale.products">
      <div class="table-container">
        <table class="table is-fullwidth">
          <tbody>
            <tr v-for="product in actualSale.products" :key="product.id">
              <th>
                <figure class="image is-96x96">
                  <img :src="product.data_product.productImage" :alt="product.data_product.productName">
                  <div class="sale_content" v-if="product.data_product.productPromotionDiscount">
                    {{ product.data_product.productPromotionDiscount }} %
                  </div>
                </figure>
              </th>
              <th>
                <span class="pd_name mb-2">{{ product.data_product.productName }}</span>
                <br>
                <span class="pd_desc has-text-weight-normal" v-if="product.data_product.productOption">
                  {{ product.data_product.productNameVariable | replaceIfLogicalFalse('') }}
                  <br>
                </span>
                <span class="pd_desc has-text-weight-normal">
                  {{ product.data_product.productCatalogue }}
                </span>
              </th>
              <th>
                <div class="is-flex is-align-items-center">
                  <p class="text_18 mr-2"> X{{ product.total_products }} </p>
                  <p class="text_18 has-text-centered" :class="{ 'text_discount' : product.data_product.productPromotionDiscount }">
                    ${{ product.data_product.productPromotionDiscount ? numeral(product.data_product.productPriceWithDiscount).format('0,0.00') : numeral(product.data_product.productPrice).format('0,0.00') }} {{ product.data_product.productCurrencyName }}
                  </p>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'

export default {
  name: 'AccordionSaleDetail',
  computed: {
    ...mapGetters(['actualSale'])
  },
  data () {
    return {
      accordionActive: false
    }
  },
  methods: {
    moment,
    numeral
  }
}
</script>

<style lang="scss" scoped>
  .accordion {
    border-radius: 18px;
    background-color: #ffffff;
  }
  .accordion_header {
    padding: 20px 40px 20px 30px;
  }
  .accordion_block {
    padding: 20px 40px 20px 30px;
    border-top: solid 1px #b5b5b5A6;
  }
  .accordion_footer {
    padding: 20px 0px 0px 0px;
  }
  .keyboard_arrow_icon {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 28px;
    font-weight: 600;
    color: var(--primary-green);
  }

  .text_18 {
    color: var(--primary-black);
  }

  .text_18.text_primary_green {
    color: var(--primary-green);
  }

  .media {
    align-items: center;
  }

  .title_media {
    margin-bottom: 1rem;
    letter-spacing: 0.2px;
  }

  .table {
    background-color: transparent;
  }
  .table th {
    border-right: solid 1px #b5b5b5A6;
    vertical-align: middle;
  }
  .table th:last-child {
    border-right: none;
  }
  .table th:first-child {
    border-left: none;
  }
  .table tr:first-child {
    border-top: solid 1px #b5b5b5A6;
  }
  .table tr {
    border-top: solid 1px #b5b5b5A6;
  }
  .table tr:last-child {
    border-bottom: none;
  }

  figure.image {
    display: block;
    margin: auto;
  }

  .sale_content {
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.15;
    padding: 5px;
    position: absolute;
    top: 0px;
    right: 0;
  }

  .pd_name {
    color: #253423;
  }
  .pd_desc {
    font-size: 14px;
    line-height: 1.14;
  }
  .text_discount {
    color: var(--orange);
  }
</style>
